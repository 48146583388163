import React, { useState, useEffect } from 'react';
import PocketBase from 'pocketbase';
import {UserIcon } from '@heroicons/react/24/outline';
import axios from 'axios';

import { ArrowLongLeftIcon, ArrowLongRightIcon } from '@heroicons/react/20/solid';
import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);



const pb = new PocketBase('https://pocketbase.fullbread.ru/');

const Stats = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [results, setResults] = useState([]);
    const [currentPage, setCurrentPage] = useState(1); // Текущая страница
    const [totalPages, setTotalPages] = useState(1);   // Общее количество страниц   
    const [loading, setLoading] = useState(false);     // Состояние загрузки
    const [searchStarted, setSearchStarted] = useState(false); // Флаг начала поиска
    const [avatars, setAvatars] = useState({});

    const [chartData, setChartData] = useState(null);

    const [chartLoading, setChartLoading] = useState(false);
    const [chartError, setChartError] = useState(null);

    const handleSearch = async (page = 1) => {
        setLoading(true);  // Включаем загрузку
        setSearchStarted(true); // Указываем, что поиск начался
        try {
            const response = await pb.collection('stats').getList(page, 25, {
                filter: `name~"${searchTerm}"`,
                sort: '-date_time'
            });

            setResults(response.items);
            setTotalPages(response.totalPages); // Устанавливаем количество страниц

            console.log(response)

        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            await setLoading(false); // Отключаем загрузку

        }
    };

    const getSteamData = async (steamID) => {
        try {
            const response = await axios.post(
                'https://steamdata.fullbread.ru/steam-users',
                { steamIds: [steamID.split(':')[1]] }
            );

            const playerData = response.data[0];
            return playerData;
        } catch (error) {
            console.error('Error fetching Steam data:', error);
            return null;
        }
    };

    useEffect(() => {
        const fetchAvatarForFirstPlayer = async () => {
            if (results.length > 0) {
                const firstPlayer = results[0]; // Получаем первую запись
                console.log('first', firstPlayer)
                if (firstPlayer.platform_id.startsWith('SteamNWI:')) {
                    const steamData = await getSteamData(firstPlayer.platform_id); // Получаем данные из Steam
                    if (steamData) {
                        setAvatars((prevAvatars) => ({
                            ...prevAvatars,
                            [firstPlayer.platform_id]: {
                                avatarUrl: steamData.avatarmedium,
                                profileUrl: steamData.profileurl,
                            },
                        }));
                    }
                }
            }
        };

        fetchAvatarForFirstPlayer(); // Вызываем функцию для получения аватара только для первого игрока
    }, [results]);





    useEffect(() => {
        if (searchTerm.trim() !== '') { // Выполняем поиск только если searchTerm не пустой
            handleSearch(currentPage);

        }
    }, [currentPage]);


    // Отслеживание изменений результатов для обновления графика
    useEffect(() => {
        if (results.length > 0) {
            fetchChartData();
        }
    }, [results]); // Обновляем график при изменении результатов

    const handlePageChange = (page) => {
        if (page >= 1 && page <= totalPages) {
            setCurrentPage(page);
        }
    };

    const PlayerStats = [
        { name: 'Убийств', value: 1 },
        { name: 'Смертей', value: 1 },
        { name: 'Убито союзников', value: 1 },

    ]

    // Функция для создания пагинации с «...»
    const getPaginationItems = () => {
        const pages = [];
        const maxVisiblePages = 3;

        if (totalPages <= maxVisiblePages * 2 + 1) {
            // Если страниц меньше, чем нужно для сокращения, показываем все
            for (let i = 1; i <= totalPages; i++) {
                pages.push(i);
            }
        } else {
            // Добавляем первые три страницы
            for (let i = 1; i <= maxVisiblePages; i++) {
                pages.push(i);
            }

            if (currentPage > maxVisiblePages + 1) {
                pages.push('...');
            }

            // Добавляем центральные страницы
            if (currentPage > maxVisiblePages && currentPage <= totalPages - maxVisiblePages) {
                pages.push(currentPage);
            }

            if (currentPage < totalPages - maxVisiblePages) {
                pages.push('...');
            }

            // Добавляем последние три страницы
            for (let i = totalPages - maxVisiblePages + 1; i <= totalPages; i++) {
                pages.push(i);
            }
        }

        return pages;
    };


    const formatDateTime = (dateString: string) => {
        const date = new Date(dateString);

        // Создаём словарь для сокращённых названий месяцев
        const shortMonthNames = [
            'янв.', 'февр.', 'март', 'апр.', 'май', 'июнь',
            'июль', 'авг.', 'сент.', 'окт.', 'нояб.', 'дек.'
        ];
        const year = date.getUTCFullYear();
        const day = date.getUTCDate(); // День месяца (UTC)
        const month = shortMonthNames[date.getUTCMonth()]; // Получаем сокращённое название месяца
        const hours = String(date.getUTCHours()).padStart(2, '0'); // Часы (UTC)
        const minutes = String(date.getUTCMinutes()).padStart(2, '0'); // Минуты (UTC)

        return `${year} ${day} ${month} ${hours}:${minutes}`; // Возвращаем строку в формате "2 окт. 16:20"
    };

    const formatShort = (dateString: string) => {
        const date = new Date(dateString);

        // Создаём словарь для сокращённых названий месяцев
        const shortMonthNames = [
            'янв.', 'февр.', 'март', 'апр.', 'май', 'июнь',
            'июль', 'авг.', 'сент.', 'окт.', 'нояб.', 'дек.'
        ];
        const year = date.getUTCFullYear();
        const day = date.getUTCDate(); // День месяца (UTC)
        const month = shortMonthNames[date.getUTCMonth()]; // Получаем сокращённое название месяца
        const hours = String(date.getUTCHours()).padStart(2, '0'); // Часы (UTC)
        const minutes = String(date.getUTCMinutes()).padStart(2, '0'); // Минуты (UTC)

        return `${hours}:${minutes}`; // Возвращаем строку в формате "2 окт. 16:20"
    };


    const fetchChartData = async () => {
        setChartLoading(true);
        setChartError(null);
        try {
            const items = [...results].reverse(); // Реверсируем данные для правильного порядка на графике
            const labels = items.map((item) => formatShort(item.date_time));
            const dataPoints = items.map((item) => Number(item.score));

            const data = {
                labels,
                datasets: [
                    {
                        label: 'Счет',
                        data: dataPoints,
                        borderColor: 'rgba(75, 192, 192, 1)',
                        backgroundColor: 'rgba(75, 192, 192, 0.2)',
                        fill: true,
                        tension: 0.4,
                        pointRadius: 5,
                        pointHoverRadius: 7,
                    },
                ],
            };

            setChartData(data); // Устанавливаем новые данные для графика
        } catch (err) {
            setChartError('Ошибка при загрузке данных графика');
            console.error('Ошибка при загрузке данных графика:', err);
        } finally {
            setChartLoading(false);
        }
    };

    const handleSearchEvent = async () => {
        if (searchTerm.trim() !== '') {
            setCurrentPage(1); // Сбрасываем на первую страницу при новом поиске
            await handleSearch(1); // Ждем завершения поиска        

        }
    };
    return (
        <div className="min-h-screen flex flex-col items-center justify-center bg-darker-light text-white">
            {/* Форма поиска */}
            <div className="mb-4 mt-8">
                <div className="flex flex-col sm:flex-row items-center mb-4">
                    <input
                        type="text"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        placeholder="Введите ник"
                        className="px-4 mb-4 py-2 rounded-lg text-black inline-flex"
                    />
                    <button
                        onClick={() => { handleSearchEvent() }}
                        className="ml-2 mb-4 px-4 py-2 bg-gray-100 rounded-lg text-black inline-flex"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24" viewBox="0 0 50 50" fill="black">
                            <path d="M 21 3 C 11.621094 3 4 10.621094 4 20 C 4 29.378906 11.621094 37 21 37 C 24.710938 37 28.140625 35.804688 30.9375 33.78125 L 44.09375 46.90625 L 46.90625 44.09375 L 33.90625 31.0625 C 36.460938 28.085938 38 24.222656 38 20 C 38 10.621094 30.378906 3 21 3 Z M 21 5 C 29.296875 5 36 11.703125 36 20 C 36 28.296875 29.296875 35 21 35 C 12.703125 35 6 28.296875 6 20 C 6 11.703125 12.703125 5 21 5 Z"></path>
                        </svg>
                        Поиск
                    </button>
                </div>
            </div>



            {results.length > 0 ? (
                // Используем последний элемент из результатов
                (() => {
                    const lastPlayer = results[0]; // Последняя запись
                    const avatarData = avatars[lastPlayer.platform_id];

                    return (
                        <>    <div className="mx-auto max-w-5xl">
                            <div className="flex flex-wrap">
                                <div className="px-4 py-6 sm:px-6 lg:px-2">
                                    {avatarData ? (
                                        <a href={avatarData.profileUrl} target="_blank" rel="noopener noreferrer">
                                            <img className="h-14 w-14 rounded-full" src={avatarData.avatarUrl} alt="Steam Avatar" />
                                        </a>
                                    ) : (
                                        <UserIcon className="h-14 w-14 text-white" aria-hidden="true" />
                                    )}
                                </div>
                                <div className="px-4 py-6 sm:px-6 lg:px-2">
                                    <span className="text-4xl font-semibold tracking-tight text-white">{lastPlayer.name}</span>
                                </div>
                            </div>
                        </div>
                        </>
                    );
                })()
            ) : (
                ''
            )}




            {chartLoading ? (
                <div className="text-white">Загрузка графика...</div>
            ) : chartError ? (
                <div className="text-red-500">{chartError}</div>
            ) : chartData ? (
                <div className="w-full max-w-5xl p-4 bg-gray-800 rounded-lg mb-8">
                    <Line
                        data={chartData}
                        options={{
                            responsive: true,
                            plugins: {
                                legend: {
                                    position: 'top',
                                    labels: {
                                        color: '#fff',
                                    }
                                 
                                },
                                tooltip: {
                                    callbacks: {
                                        label: (tooltipItem) => `Счет: ${tooltipItem.raw}`,
                                    },
                                },
                            },
                            scales: {
                                x: {
                                    title: {
                                        display: true,
                                        text: 'Время',
                                        color: '#fff',
                                    },
                                    ticks: {
                                        color: '#fff',
                                    },
                                },
                                y: {
                                    title: {
                                        display: true,
                                        text: 'Счет',
                                        color: '#fff',
                                    },
                                    ticks: {
                                        color: '#fff',
                                    },
                                },
                            },
                        }}
                    />
                </div>
            ) : null}

            {/* Загрузка */}
            {
                loading ? (
                    <div>Загрузка...</div>
                ) : (
                    <>
                        {/* Проверка: если searchTerm пустой, не показываем таблицу */}
                        {searchStarted && searchTerm.trim() !== '' && results.length > 0 ? (

                            <div className="overflow-x-auto mb-12 w-full max-w-5xl">
                                <table className="mt-6 w-full whitespace-nowrap text-left bg-gray-800 rounded-lg">
                                    <thead className="border-b border-white/10 text-sm leading-6 text-white">
                                        <tr>
                                            <th className="py-2 pl-4 pr-8 font-semibold">Дата и время (мск)</th>
                                            <th className="py-2 pr-8 font-semibold">Игрок</th>
                                            <th className="py-2 pr-8 font-semibold">Счет</th>
                                            <th className="py-2 pr-8 font-semibold">Сервер</th>
                                        </tr>
                                    </thead>
                                    <tbody className="divide-y divide-white/5">
                                        {results.map((match) => (
                                            <tr key={match.id}>
                                                <td className="py-2 pl-4 pr-8">{formatDateTime(match.date_time)}</td>
                                                <td className="py-2 pr-8">{match.name}</td>
                                                <td className="py-2 pr-8">{match.score}</td>
                                                <td className="py-2 pr-8">{match.server}</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>


                                {/* Пагинация */}
                                {totalPages > 1 && (
                                    <nav className="flex items-center justify-between px-4 sm:px-0 mt-4">
                                        <div className="-mt-px flex w-0 flex-1">
                                            <button
                                                onClick={() => handlePageChange(currentPage - 1)}
                                                disabled={currentPage === 1}
                                                className={`inline-flex items-center border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-white ${currentPage === 1 && 'cursor-not-allowed'}`}
                                            >
                                                <ArrowLongLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                                                Назад
                                            </button>
                                        </div>
                                        <div className="hidden md:-mt-px md:flex">
                                            {getPaginationItems().map((page, index) =>
                                                page === '...' ? (
                                                    <span key={index} className="inline-flex items-center px-4 pt-4 text-sm font-medium text-gray-500">
                                                        ...
                                                    </span>
                                                ) : (
                                                    <button
                                                        key={page}
                                                        onClick={() => handlePageChange(page)}
                                                        className={`inline-flex items-center  px-4 pt-4 text-sm font-medium ${currentPage === page ? ' text-white' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-white'}`}
                                                    >
                                                        {page}
                                                    </button>
                                                )
                                            )}
                                        </div>
                                        <div className="-mt-px flex w-0 flex-1 justify-end">
                                            <button
                                                onClick={() => handlePageChange(currentPage + 1)}
                                                disabled={currentPage === totalPages}
                                                className={`inline-flex items-center border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-white ${currentPage === totalPages && 'cursor-not-allowed'}`}
                                            >
                                                Вперед
                                                <ArrowLongRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                                            </button>
                                        </div>
                                    </nav>
                                )}
                            </div>
                        ) : (
                            searchStarted && searchTerm.trim() !== '' && <div className="text-white">Ничего не найдено</div>
                        )}
                    </>
                )
            }
            <div className="text-sm">Статистика работает в тестовом режиме</div>
        </div >
    );
};

export default Stats;
