// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
  }
  
  .tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 50%;
    margin-left: -60px;
  }
  
  .tooltip:hover .tooltiptext {
    visibility: visible;
  }`, "",{"version":3,"sources":["webpack://./src/custom.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,qBAAqB;IACrB,+BAA+B;EACjC;;EAEA;IACE,kBAAkB;IAClB,YAAY;IACZ,uBAAuB;IACvB,WAAW;IACX,kBAAkB;IAClB,kBAAkB;IAClB,cAAc;;IAEd,yBAAyB;IACzB,kBAAkB;IAClB,UAAU;IACV,SAAS;IACT,SAAS;IACT,kBAAkB;EACpB;;EAEA;IACE,mBAAmB;EACrB","sourcesContent":[".tooltip {\n    position: relative;\n    display: inline-block;\n    border-bottom: 1px dotted black;\n  }\n  \n  .tooltip .tooltiptext {\n    visibility: hidden;\n    width: 120px;\n    background-color: black;\n    color: #fff;\n    text-align: center;\n    border-radius: 6px;\n    padding: 5px 0;\n    \n    /* Position the tooltip */\n    position: absolute;\n    z-index: 1;\n    top: 100%;\n    left: 50%;\n    margin-left: -60px;\n  }\n  \n  .tooltip:hover .tooltiptext {\n    visibility: visible;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
