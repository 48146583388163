import { useState } from 'react'
import { Dialog } from '@headlessui/react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline'

const navigation = [
  { name: 'Статистика', href: '/stats' },
  { name: 'Группа Steam', href: 'https://steamcommunity.com/groups/fullbreadins', target: '_blank' }
]

export default function Example() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <header className="bg-darker">
      <nav className="mx-auto flex items-center justify-between p-3 container" aria-label="Global">
        <div className="flex flex-1">

          <div className="hidden lg:flex lg:gap-x-12">
            <a href="/" className="text-white selected text-sm font-semibold leading-6 p-2 hover:text-yellowcustom">
              FULLBREAD
            </a>
            {navigation.map((item) => (
              <a key={item.name} href={item.href} target={item.target} className="text-white p-2 selected text-sm font-semibold leading-6 hover:text-yellow-100">
                {item.name}
              </a>
            ))}

          </div>
          <div className="flex lg:hidden">
            <button
              type="button"
              className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(true)}
            >
              <span className="sr-only">Open main menu</span>
              <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
        </div>
        <a href="#" className="-m-1.5 p-1.5 invisible">
          <span className="text-white font-medium">FULLBREAD</span>

        </a>
        <div className="flex flex-1 justify-end invisible">
          <a href="#" className="text-sm font-semibold leading-6 text-white">
            Log in <span aria-hidden="true">&rarr;</span>
          </a>
        </div>
      </nav>
      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 left-0 z-10 w-full overflow-y-auto bg-darker px-6 py-6">
          <div className="flex items-center justify-between">
            <div className="flex flex-1">
              <button
                type="button"
                className="-m-2.5 rounded-md p-2.5 text-gray-100"
                onClick={() => setMobileMenuOpen(false)}
              >
                <span className="sr-only">Close menu</span>
                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              </button>
            </div>
            <a href="#" className="-m-1.5 p-1.5 invisible">
              <span className="text-white font-medium">FULLBREAD</span>
            </a>

          </div>
          <div className="mt-6 space-y-2">
            <a
             
              href="/"
              className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:text-yellowcustom"
            >
              FULLBREAD
            </a>
            {navigation.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-white hover:text-yellow-100"
              >
                {item.name}
              </a>
            ))}
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  )
}
